<template>
    <NuxtLink to="/products/weapon-integration" class="cursor-default">
        <div class="group m-[2vw] md:w-[45vw] min-w-[200px] max-w-screen-md md:max-w-[700px] relative overflow-clip">
            <NuxtImg src="/images/weapon-integration-product-card.webp" alt="" class="group-hover:scale-[103%] transition duration-300 w-full overflow-hidden" />
            <div class="absolute flex flex-col inset-0 bg-black bg-opacity-40 start-1/2 w-1/2 text-white">
                <div class="absolute top-5 right-5 border-white w-[20%] h-[11%] border font-light flex items-center justify-center">
                    <p class="text-[6vw] md:text-[3vw]">2</p>
                </div>
                <div class="px-[2vw] md:px-[1vw] flex flex-col absolute bottom-5 left-0 right-0 max-h-1/2">
                    <span class="w-full font-bold text-base md:text-[1.6vw] md:leading-[2vw] xl:text-[18pt] xl:leading-snug">
                        Weapon integration
                    </span>
                    <hr class="mt-3 mb-3 md:mt-[2vw] md:mb-[1.5vw] xl:mt-5 xl:mb-5 h-px relative -start-10 md:-start-[5vw] w-20 xl:w-[120px] xl:-start-16" />
                    <span class="w-full text-xs md:text-[1.15vw] md:leading-[1.5vw] xl:text-base xl:leading-snug">
                        Enabling the ability to mount objects opens up an entirely new spectrum of possible applications.
                    </span>
                    <span class="text-green-500 mt-3 mb-3 xl:mt-10 text-xs xl:text-base leading-[3.3vw] md:mt-[2.35vw] md:mb-[2vw] md:text-[1vw] md:leading-[2vw] cursor-pointer">
                        Explore >
                    </span>
                </div>
            </div>
        </div>
    </NuxtLink>
</template>